<template>
  <v-simple-table class="mb-6">
    <thead>
      <!-- Table Column Titles -->
      <tr>
        <th :style="tableHeaderFooterStyle()">Date</th>
        <th
          v-for="stat in visibleStats"
          :key="stat.label"
          :style="tableHeaderFooterStyle(colors[stat.color])"
        >
          {{ stat.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <!-- Table Rows -->
      <tr v-for="item in items" :key="item.time">
        <td :style="{ backgroundColor: `#00000009` }">{{ formatDate(item.time) }}</td>
        <td
          v-for="stat in visibleStats"
          :key="stat.label"
          :style="getTableCellStyle(colors[stat.color])"
        >
        {{ getFormattedValue(item.stats[0].metrics, stat.path, stat.isCurrency) }}
        </td>
      </tr>
      <!-- 'Totals' Row -->
      <tr class="text-subtitle-2">
        <td :style="{ backgroundColor: `#00000009` }">Total</td>
        <td
          v-for="stat in visibleStats"
          :key="stat.label"
          :style="getTableCellStyle(colors[stat.color])"
        >
          <span v-if="stat.isCurrency">
            {{ getFormattedValue(totals, stat.path, stat.isCurrency) }}
          </span>
          <span v-else>
            {{ getFormattedValue(totals, stat.path, stat.isCurrency) }}
          </span>
        </td>
      </tr>
    </tbody>
    <!-- Table Footer (show if more than 14 rows) -->
    <tfoot v-if="items.length > 14">
      <tr>
        <th :style="tableHeaderFooterStyle()"></th>
        <th
          v-for="stat in visibleStats"
          :key="stat.label"
          :style="tableHeaderFooterStyle(colors[stat.color], 'footer')"
        >
          {{ stat.label }}
        </th>
      </tr>
    </tfoot>
  </v-simple-table>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "StatsTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
    totals: {
      type: Object,
      required: true,
    },
    selectedStatsGroup: {
      type: String,
      required: true,
    },
    statsConfig: {
      type: Object,
      required: true,
    },
    revenueCurrency: {
      type: String,
      default: null,
    },
    colors: {
      type: Object,
      required: true,
    },
    binSize: {
      type: String,
      required: true,
    },
  },

  computed: {
    visibleStats() {
      const config = this.statsConfig[this.selectedStatsGroup];
      if (!config) return [];

      return config.filter(stat => {
        if (this.selectedStatsGroup === "overview") {
          // For overview, only show stats with non-zero totals
          const value = this.getStatValue(this.totals, stat.path);
          return value > 0;
        }
        return true; 
      });
    },

    numberFormatter() {
      try {
        return new Intl.NumberFormat();
      } catch(err) {
        console.warn("Failed to create number formatter", err);
      }
      return null;
    },

    revenueNumberFormatter() {
      try {
        if (this.revenueCurrency)
          return new Intl.NumberFormat(undefined, {style: "currency", currency: this.revenueCurrency});
      } catch(err) {
        console.warn("Failed to create currency number formatter", err);
      }
      return null;
    },
  },

  methods: {
    formatDate(date) {
      return this.binSize === "day"
        ? dayjs(date).format("L")
        : dayjs(date).format("MMMM YYYY");
    },

    tableHeaderFooterStyle(color, headerOrFooter = "header") {
      const widthPercentage = `${100 / (this.visibleStats.length + 1)}%`;

      if (headerOrFooter === "footer") {
        return {
          borderTop: color ? `solid 1px ${color}` : undefined,
          color: color,
          width: widthPercentage,
        };
      }

      return {
        borderBottom: color ? `solid 1px ${color}` : undefined,
        color: color,
        width: widthPercentage,
      };
    },

    getTableCellStyle(color) {
      return {
        backgroundColor: color ? `${color}20` : undefined,
      };
    },

    getStatValue(obj, path, isCurrency = false) {
      let arr = path.split(".")
      let value = arr.reduce((acc, part) => acc && acc[part], obj) || 0;
      
      if (isCurrency) {
        return value / 100;
      }
      return value;
    },

    getFormattedValue(obj, path, isCurrency = false) {
      let value = this.getStatValue(obj, path, isCurrency);
      if (isCurrency) {
        return this.revenueNumberFormatter ? this.revenueNumberFormatter.format(value) : value;
      } else {
        return this.numberFormatter ? this.numberFormatter.format(value) : value;
      }
    }
  },
};
</script>

<style scoped>
th, td {
  text-align: center !important;
}
</style>