<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    offset-y
    nudge-bottom="4"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        class="text-transform-none text-subtitle-1"
        v-on="on"
        v-bind="attrs"
        :disabled="disabled"
      >
        <div class="scroll-container">
          <span ref="scrollText" class="scroll" :class="{ 'animate': textShouldScroll }" v-if="value">
            {{ profileNameOrId }}
          </span>
          <span v-else>Select a profile</span>
        </div>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <v-text-field
          spellcheck="false"
          autofocus
          v-model="filter"
          solo
          dense
          prepend-inner-icon="mdi-magnify"
          clearable
          hide-details
        ></v-text-field>
        <v-btn-toggle class="mt-2" v-model="filterType">
          <v-btn x-small value="install">Installs</v-btn>
          <v-btn x-small value="event">Events</v-btn>
        </v-btn-toggle>
        <div v-if="isLoading" class="mt-4 text-center">
          <v-progress-circular indeterminate width="2" size="24" />
        </div>
        <v-list v-else-if="filterProfiles.length">
          <div class="profile-list" ref="profileList">
            <v-list-item
              v-for="profile in filterProfiles"
              :key="profile.id"
              link
              color="primary"
              :input-value="profile.id === value"
              @click="selectProfile(profile)"
            >
              <v-list-item-title>{{ profile.name || profile.id }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
        <div v-else class="mt-4 pl-4">No profiles found</div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ProfilePicker",

  props: {
    value: String,
    disabled: Boolean,
    autoSelect: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    showMenu: false,
    filter: null,
    profiles: [],
    isLoading: false,
    filterType: null,
    profileName: null,
    textShouldScroll: false, 
  }),

  computed: {
    filterProfiles() {
      let filteredProfiles = this.profiles;

      if (this.filterType) {
        filteredProfiles = filteredProfiles.filter((profile) =>
          profile.id.startsWith(`${this.filterType}.`)
        );
      }
      if (!this.filter) {
        return filteredProfiles;
      }
      let lowerCaseFilter = this.filter.toLowerCase();
      return filteredProfiles.filter(
        (profile) =>
          profile.id.toLowerCase().includes(lowerCaseFilter) ||
          (profile.name && profile.name.toLowerCase().includes(lowerCaseFilter))
      );
    },
    profileNameOrId() {
      return this.profileName ? this.profileName : this.value;
    },
  },

  async created() {
    this.isLoading = true;
    try {
      this.profiles = await this.$root.getProfiles();
      if (this.autoSelect && this.profiles.length === 1)
        this.$emit("input", this.profiles[0].id);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    selectProfile(profile) {
      this.$emit("input", profile.id);
      this.showMenu = false;
    },

    checkTextOverflow() {
      if (this.$refs.scrollText) {
        const textWidth = this.$refs.scrollText.scrollWidth;
        this.textShouldScroll = textWidth > 250;
      }
    },
  },

  watch: {
    value: {
      async handler(newVal) {
        if (newVal) {
          this.profileId = null;
          let profile = await this.$root.getProfile(newVal);
          this.profileName = profile.name;
          this.$nextTick(() => this.checkTextOverflow());
        }
      },
      immediate: true,
    },

    showMenu(newVal) {
      if (newVal) {
        this.filter = null;
        setTimeout(() => {
          if (this.$refs.profileList) this.$refs.profileList.scrollTop = 0;
        }, 50);
      }
    },
  },
};
</script>

<style scoped>
.text-transform-none {
  text-transform: none;
}

.profile-list {
  max-height: 400px;
  overflow-y: scroll;
}

.scroll-container {
  max-width: 250px;
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.scroll {
  display: inline-block;
  position: relative;
  padding-right: 0.5rem;
}

.scroll.animate {
  animation: move 7s ease-in-out infinite alternate;
  animation-delay: 2s;
}

@keyframes move {
  0% {
    transform: translate(0, 0);
    left: 0%;
  }
  100% {
    transform: translate(-100%, 0);
    left: 100%;
  }
}
</style>