<template>
  <v-tooltip bottom :disabled="!tooltipText" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center" :class="{hasTooltip: tooltipText}" v-bind="attrs" v-on="on">
        <v-icon v-if="tooltipText" small class="mr-1">mdi-information-outline</v-icon>
        <span>{{message}}</span>
      </div>
    </template>
    <span v-if="tooltipText">{{tooltipText}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "PaymentMessage",
  props: {
    message: String
  },

  computed: {
    tooltipText() {
      if (!this.message) return null;

      if (/^Service Error\s2\d\d$/.test(this.message)) {
        return "Service errors in the 2XX range relate to network or internet issues. Ensure the terminal has a reliable internet connection."
      
      } else if (this.message == "Commit failed") {
        return "The payment was approved, but an issue occurred while finalising the transaction. The customer may not have been charged as expected."
      }

      return null;
    }
  }
}
</script>

<style scoped>
.hasTooltip {
  cursor: default;
}

.hasTooltip span {
  text-decoration: underline dotted;
  text-underline-offset: 4px;
}
</style>