<template>
  <v-dialog v-model="showDialog" fullscreen scrollable transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar dark>
          <v-icon class="mr-3">mdi-image-multiple</v-icon>
          <v-toolbar-title>Asset Library</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text
        class="pt-6"
        @dragenter.prevent.stop="dragEnter"
        @dragover.prevent.stop="dragEnter"
        @dragleave.prevent.stop="dragLeave"
        @drop.prevent.stop="fileDropped">
        <v-row class="row-divided">
          <v-col cols="8" @click="selectedAsset = null">
            
            <!-- Upload box -->
            <v-alert v-if="isUploading" color="info" text class="mb-6 py-6">
              <v-progress-circular indeterminate color="info" :size="20" :width="2" class="mr-4"></v-progress-circular>
              Uploading...
            </v-alert>
            <v-alert v-else-if="canCreate" :color="draggingStatus" icon="mdi-upload" text class="mb-6 py-6">
              <input ref="file" type="file" accept="image/*" class="d-none" @change="fileSelected" />
              Drag and drop your assets here, or <a @click="chooseFile">choose files</a> to upload.
            </v-alert>

            <v-row v-if="items.length">
              <v-col v-for="item in items" :key="item.id" cols="6" sm="4" md="3">
                <v-card @click.stop="selectedAsset = item" :elevation="1" class="fill-y">
                  <v-img :src="item.url" contain :aspect-ratio="3/2"></v-img>
                  <v-card-title class="text-subtitle-2">
                    {{item.name}}
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <async-state :promise="promise" :has-data="items.length > 0">
                <div v-if="nextPageToken" class="text-center my-4">
                  <v-btn text @click="loadMore">
                    Load more
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </async-state>
          </v-col>
          <v-col v-if="selectedAsset" cols="4">
            <v-img :src="selectedAsset.url" contain height="200"></v-img>
            
            <v-divider class="my-6" />
            
            <label-value label="Name" :value="selectedAsset.name" />
            <label-value label="Created" :value="$formatDateTime(selectedAsset.create_time)" />
            <label-value label="Type" :value="selectedAsset.mime_type" />
            <label-value label="Size" :value="prettyBytes(selectedAsset.size_bytes)" />
            
            <v-divider class="my-6" />
            
            <v-row>
              <v-col>
                <v-btn :color="copySuccess ? 'success' : null" outlined block @click="copyURL">
                  <template v-if="!copySuccess">
                    <v-icon left>mdi-content-copy</v-icon>
                      Copy URL
                  </template>
                  <template v-else>
                    <v-icon left>mdi-check</v-icon>
                    Copied!
                  </template>
                </v-btn>
              </v-col>
              <v-col v-if="canDelete" cols="12" md="auto">
                <v-btn color="error" outlined block @click="deleteAsset">
                  <v-icon left>mdi-delete</v-icon>
                  Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <confirm-dialog ref="confirm" />
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import AsyncState from './AsyncState.vue'
import LabelValue from './LabelValue.vue'
import ConfirmDialog from './ConfirmDialog.vue'
import prettyBytes from "pretty-bytes";

export default {
  name: "DeliveryAssetsDialog",
  components: {AsyncState, LabelValue, ConfirmDialog},

  data() {
    return {
      showDialog: false,
      profileId: null,

      items: [],
      promise: null,
      nextPageToken: null,
      cancelToken: null,

      selectedAsset: null,

      draggingStatus: null, // success | error
      isUploading: false,

      copySuccess: false,
      copySuccessTimer: null
    }
  },

  computed: {
    canCreate() {
      return (
        this.profileId &&
        this.$root.permissions.canPerform("create_delivery_asset", {profile: this.profileId}));
    },

    canDelete() {
      return (
        this.selectedAsset &&
        this.$root.permissions.canPerform("delete_delivery_asset", [
          `profile:${this.profileId}`,
          `profile:${this.profileId}/delivery-assets:${this.selectedAsset.id}`
        ])
      )
    },
  },

  methods: {
    open(profileId) {
      this.profileId = profileId;
      this.showDialog = true;
      this.selectedAsset = null;
      this.loadInit();
    },

    close() {
      this.showDialog = false;
      this.cancelToken?.cancel();
    },

    loadInit() {
      this.items = [];
      this.nextPageToken = null;
      this.loadMore();
    },

    loadMore() {
      this.promise = (async () => {
        this.cancelToken = axios.CancelToken.source();

        let response = await this.$api.get(`/profiles/${this.profileId}/delivery-assets`, {
          params: {
            order_by: "desc",
            page_size: 20,
            page_token: this.nextPageToken
          },
          cancelToken: this.cancelToken.token
        });
          
        this.items = this.items.concat(response.data.items);
        this.nextPageToken = response.data.next_page_token;
      })();
    },

    dragEnter(event) {
      let items = event.dataTransfer.items;
      if (items?.length == 1 && items[0].kind == "file" && items[0].type?.startsWith("image/")) {
        this.draggingStatus = "success";
      } else {
        this.draggingStatus = "error";
      }
    },

    dragLeave() {
      this.draggingStatus = null;
    },

    fileDropped(event) {
      this.draggingStatus = null;

      let files = event.dataTransfer.files;
      if (files?.length == 1 && this.canCreate && !this.isUploading) {
        this.uploadFile(files[0]);
      }
    },

    chooseFile() {
      this.$refs.file.click();
    },

    fileSelected() {
      let file = this.$refs.file.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },

    async uploadFile(file) {
      // validate file
      if (!file.type.startsWith("image/")) {
        this.$root.showSnackbar("Unsupported file type. Please upload an image.", "error");
        return;
      }

      this.isUploading = true;

      try {
        // create upload
        let upload = await this.$api.post('/uploads', {
          mime_type: file.type,
          size_bytes: file.size
        });
      
        // upload file data
        await axios.put(upload.data.upload_url, file, {
          headers: {
            "Content-Type": upload.data.mime_type
          }
        });

        // create asset from upload
        let asset = await this.$api.post(`/profiles/${this.profileId}/delivery-assets`, {
          upload_id: upload.data.id,
          name: file.name
        });

        // prepend asset to array
        this.items.unshift(asset.data);

      } catch(err) {
        this.$root.showSnackbar(err);
      } finally {
        this.isUploading = false;
      }
    },

    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.selectedAsset.url);
        this.copySuccess = true;
        clearTimeout(this.copySuccessTimer);
        this.copySuccessTimer = setTimeout(() => { this.copySuccess = false }, 2000);
      } catch (err) {
        this.copySuccess = false;
        console.error('Failed to copy URL: ', err);
      }
    },

    async deleteAsset() {
      try {
        if (await this.$refs.confirm.present({
          title: `Delete ${this.selectedAsset.name}?`,
          message: "This will permanently delete the asset, which may cause emails that have already been sent to display incorrectly.",
          confirm: "Delete",
          color: "error",
          callback: async () => {
            await this.$api.delete(`/profiles/${this.profileId}/delivery-assets/${this.selectedAsset.id}`);
          }
        })) {
          this.$root.showSnackbar("Asset deleted successfully");
          this.items = this.items.filter(item => item !== this.selectedAsset);
          this.selectedAsset = null;
        }
      } catch(err) {
        this.$root.showSnackbar(err);
      }
    },

    prettyBytes
  }
};
</script>