<template>
  <v-container class="px-lg-12">
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">Confirm Deletion</v-card-title>
        <v-card-text>Are you sure you want to delete this schedule?</v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="error" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click="deleteSchedule(dialogIndex)">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <div>
        <h2 class="text-h4 mb-1">Customisation</h2>
        <span class="subtitle-1 font-weight-light">Configure branding and experience options to match your needs, with the ability to schedule changes in advance.</span>
      </div>
    </div>

    <!-- Card -->
    <v-row>
      <v-col>
        <v-card>
          <v-card-header class="d-flex justify-space-between align-center">
            <bucket-profile-picker v-model="profileName" />
            <v-btn text @click="addNewSchedule" v-if="profile">
              <v-icon left>mdi-plus</v-icon>
              Create Schedule
            </v-btn>
          </v-card-header>
          <v-card-text>
            <v-row v-if="loading" justify="center" align="center" style="height: 100%;">
              <v-col cols="auto">
                <v-progress-circular indeterminate width="2" size="24"></v-progress-circular>
              </v-col>
            </v-row>
            <v-simple-table v-else-if="rows.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Description</th>
                    <th class="text-left">Start</th>
                    <th class="text-left">End</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in rows" :key="index">
                    <template v-if="index === 0">
                      <td><b>{{ row.description }}</b></td>
                      <td><v-icon color="black" left>mdi-minus</v-icon></td>
                      <td><v-icon color="black" left>mdi-minus</v-icon></td>
                    </template>
                    <template v-else>
                      <td>{{ row.description }}</td>
                      <td>{{ formatDateTime(row.start) }}</td>
                      <td>{{ formatDateTime(row.end) }}</td>
                    </template>
                    <td>
                      <v-btn color="primary" text @click="editSchedule(index)">
                        Edit
                      </v-btn>
                      <v-btn text color="error" v-if="index > 0" @click="showDeleteDialog(index)">Delete</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-else-if="!loading" class="text-center">
              No data to display.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BucketProfilePicker from "../components/BucketProfilePicker.vue";

export default {
  name: "ProfileTable",
  components: {
    BucketProfilePicker,
  },
  data() {
    return {
      profileName: null,
      profile: null,
      loading: false,
      dialog: false,
      dialogIndex: null,
    };
  },
  created() {
    if (this.$route.params.profileName) {
      this.profileName = this.$route.params.profileName;
      this.fetchProfile(this.profileName);
    }
  },
  computed: {
    rows() {
      if (this.profile) {
        const defaultRow = {
          description: 'Default Configuration',
        };
        const schedules = this.profile.schedules.map((schedule) => {
          return {
            id: schedule.id,
            title: schedule.variables.title,
            description: schedule.description,
            start: schedule.start_time,
            end: schedule.end_time,
          };
        });
        return [defaultRow, ...schedules];
      }
      return [];
    },
  },
  watch: {
    profileName(newVal) {
      this.updateUrl(newVal);
      this.fetchProfile(newVal);
    },
  },
  methods: {
    showDeleteDialog(index) {
      this.dialogIndex = index;
      this.dialog = true;
    },
    formatDateTime(dateTimeString) {
    if (dateTimeString) {
      const dateTime = this.$dayjs(dateTimeString);
      return dateTime.calendar(null, {
        lastWeek: 'LLLL',
        nextWeek: 'LLLL',
        sameElse: 'LLLL',
      })
    }
    return '';
  },
    async deleteSchedule(index) {
      this.dialog = false;
      this.profile.schedules.splice(index - 1, 1);
      await this.$root.updateProfile(this.profile);
    },
    editSchedule(index) {
      if (index === 0) {
        this.$router.push(`/profile/${this.profileName}/default`);
      } else {
        this.$router.push(`/profile/${this.profileName}/schedule/${index}`);
      }
    },
    updateUrl(profileName) {
      if (profileName) {
        this.$router.replace(`/profiles/${profileName}`);
      } else {
        this.$router.replace('/profiles');
      }
    },
    async fetchProfile(profileName) {
      if (profileName) {
        try {
          this.loading = true;
          this.profile = await this.$root.getProfileFromBucket(profileName);
          this.loading = false;
        } catch (err) {
          console.error('Error fetching profile', err);
          this.$root.showSnackbar(err);
          this.loading = false;
        }
      } else {
        this.profile = null;
      }
    },
    addNewSchedule() {
      this.$router.push(`/profile/${this.profileName}/new-schedule`);
    },
  },
};
</script>
