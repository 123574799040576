<template>
  <div>
    <v-simple-table v-if="items.length">
      <thead>
        <tr>
          <th>Created time</th>
          <th v-for="attr in schema" :key="attr.id">{{attr.id}}</th>
          <th><v-icon small title="Linked captures">mdi-camera</v-icon></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredItems" :key="item.id">
          <td>
            <router-link :to="{name: 'data-detail', params: {id: item.id}}">
              {{$formatDateTime(item.create_time)}}
            </router-link>
          </td>
          <td v-for="attr in schema" :key="attr.id">
            <template v-if="!isUndefinedOrNull(item.attributes[attr.id])">{{item.attributes[attr.id]}}</template>
            <template v-else>-</template>
            </td>
          <td>
            <template v-if="item.deliverables_count">{{item.deliverables_count}}</template>
            <template v-else>-</template>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <async-state :promise="promise" :has-data="items.length > 0">
        <div v-if="nextPageToken" class="text-center my-4">
          <v-btn text @click="loadMore">
            Load more
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
    </async-state>
  </div>
</template>

<script>
import AsyncState from "./AsyncState.vue"
import axios from 'axios'
import Filters from "../filters"

export default {
  name: "SubmissionsTable",
  components: {AsyncState},

  props: {
    profileId: String,
    deliverableId: String,
    pageSize: Number,
    reverse: Boolean,
    startTime: Date,
    endTime: Date,
    filters: Filters
  },

  data() {
    return {
      profile: null,
      items: [],
      attributeKeys: new Set(),
      promise: null,
      nextPageToken: null,
      cancelToken: null
    }
  },

  computed: {
    baseParams() {
      return {
        profile_id: !this.deliverableId ? this.profileId : undefined,
        deliverable_id: this.deliverableId,
        order_by: this.reverse ? "desc" : "asc",
        start_time: this.startTime?.toISOString(),
        end_time: this.endTime?.toISOString()
      }
    },

    filteredItems() {
      return this.filters ? this.filters.apply(this.items) : this.items;
    },

    schema() {
      let schema = this.profile ? this.profile.schemas.submission.attributes : [];
      let existingKeys = new Set(schema.map(attr => attr.id));
      for (let key of this.attributeKeys) {
        if (!existingKeys.has(key)) {
          schema.push({id: key})
        }
      }
      return schema;
    }
  },

  methods: {
    loadInit() {
      this.items = [];
      this.attributeKeys = new Set();
      this.nextPageToken = null;
      this.loadMore();
    },

    loadMore() {
      this.promise = (async () => {
        if (this.cancelToken)
          this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let response = await this.$api.get("/submissions", {
          params: {
            ...this.baseParams,
            page_size: this.pageSize,
            page_token: this.nextPageToken
          },
          cancelToken: this.cancelToken.token
        });
          
        this.items = this.items.concat(response.data.items);

        for (let item of response.data.items) {
          for (let attribute in item.attributes) {
            this.attributeKeys.add(attribute);
          }
        }

        this.nextPageToken = response.data.next_page_token;
      })();
    },

    isUndefinedOrNull(value) {
      return typeof(value) === "undefined" || value === null;
    }
  },

  watch: {
    baseParams: {
      handler() {
        this.loadInit();
      },
      immediate: true
    },

    profileId: {
      async handler(newValue) {
        this.profile = null;
        if (newValue) {
          this.profile = await this.$root.getProfile(newValue);
        }
      },
      immediate: true
    }
  }
}
</script>

<style>

</style>