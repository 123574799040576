<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <div>
        <h2 class="text-h4 mb-1">Profiles</h2>
        <span class="subtitle-1 font-weight-light">View and update profile settings.</span>
      </div>
      <v-btn v-if="canCreate" text exact :to="{name: 'profile-edit'}">
        <v-icon left>mdi-plus</v-icon>
        Create
      </v-btn>
    </div>

    <!-- Card -->
    <v-card>
      <v-card-text>
        <!-- Table -->
        <v-simple-table v-if="items.length">
          <thead>
            <tr>
              <th>No.</th>
              <th>ID</th>
              <th>Name</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item.id">
              <td width="8%">{{ index + 1 }}</td>
              <td width="20%">
                <router-link :to="{name: 'profile-detail', params: {profileId: item.id}}">
                  {{item.id}}
                </router-link>
              </td>
              <td>{{ item.name || "-" }}</td>
              <td>
                {{formattedDate(item.create_time)}}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <async-state :promise="promise" :has-data="items.length > 0">
          <div v-if="nextPageToken" class="text-center my-4">
            <v-btn text @click="loadMore">
              Load more
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </async-state>

      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs';
import AsyncState from '../components/AsyncState.vue'

export default {
  name: "Users",
  components: { AsyncState },

  data() {
    return {
      items: [],
      promise: null,
      nextPageToken: null,
      cancelToken: null
    };
  },

  created() {
    this.loadInit();
  },

  computed: {
    canCreate() {
      return this.$root.permissions.canPerform("create_profile", "*");
    },
  },

  methods: {
    loadInit() {
      this.items = [];
      this.nextPageToken = null;
      this.loadMore();
    },

    loadMore() {
      this.promise = (async () => {
        if (this.cancelToken)
          this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let response = await this.$api.get("/profiles", {
          params: {
            page_size: 100,
            page_token: this.nextPageToken,
            order_by: "desc"
          },
          cancelToken: this.cancelToken.token
        });
          
        this.items = this.items.concat(response.data.items);
        this.nextPageToken = response.data.next_page_token;
      })();
    },

    formattedDate(create_time) {
      return dayjs(create_time).format('MMMM D YYYY')
    },
  }
};

</script>