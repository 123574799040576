<template>
  <v-tooltip bottom :color="color" :disabled="!tooltipText" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-chip class="text-capitalize" :color="color" v-bind="{...$attrs, ...attrs}" v-on="on">
        {{text}}
      </v-chip>
    </template>
    <span v-if="tooltipText">{{tooltipText}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "StatusChip",
  props: {
    status: String,
    context: String
  },

  computed: {
    text() {
      return this.status.split("_").join(" ");
    },

    color() {
      if (this.status === "complete" || this.status == "confirmed" || this.status === "delivered")
        return "success";
      if (this.status === "failed" || this.status === "declined" || this.status === "complained")
        return "error";
      if (this.status === "cancelled" || this.status === "bounced" || this.status === "suppressed")
        return "warning";
      return "info";
    },

    tooltipStrings() {
      return {
        deliveries: {
          sent: "The email was successfully sent but hasn't been confirmed as received.",
          delivered: "The email reached the recipient's inbox successfully.",
          bounced: "The email couldn't be delivered, possibly due to an invalid address or rejection by the recipient's mail server.",
          complained: "The recipient reported the email as spam. We will not deliver to this address again.",
          suppressed: "The email wasn't sent because the address is on a do-not-send list due to previous issues."
        },
        payments: {
          complete: "Payment was successful and funds transferred.",
          cancelled: "Payment was initiated but stopped before funds were transferred. This may leave a temporary pending charge on the customer's account.",
          declined: "Payment was rejected by the card issuer.",
          failed: "Payment failed due to a hardware failure, internet outage or other system error."
        }
      }[this.context]
    },

    tooltipText() {
      return this.tooltipStrings ? this.tooltipStrings[this.status] : null;

    }
  }
}
</script>