<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <div>
        <h2 class="text-h4 mb-1">Monitoring</h2>
        <span class="subtitle-1 font-weight-light">Keep track of system health and device status in real-time.</span>
      </div>
    </div>

    <!-- Filters -->
    <div class="d-flex justify-space-between align-center">
      <profile-picker v-model="profileId" />
      <v-btn v-if="profileId" icon :loading="isSilentLoading" @click="silentLoad">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <v-divider class="mb-6" />

    <!-- Cards -->
    <async-state v-if="profileId" :promise="promise" :has-data="items && items.length > 0">
      <v-row>
        <v-col v-for="item in items" :key="item.id" cols="12" sm="6">
          <monitored-resource-card :resource="item" />
        </v-col>
      </v-row>
    </async-state>
    <p v-else class="text-center grey--text my-4">
      Select a profile to view resources
    </p>
  </v-container>
</template>

<script>
import axios from 'axios'
import ProfilePicker from '../components/ProfilePicker.vue'
import AsyncState from '../components/AsyncState.vue'
import MonitoredResourceCard from '../components/MonitoredResourceCard.vue';

export default {
  name: "Monitoring",
  components: {ProfilePicker, AsyncState, MonitoredResourceCard},

  data() {
    return {
      items: null,
      promise: null,
      cancelToken: null,
      isSilentLoading: false,
      silentLoadingTimer: null, // prevent reload button from being hit to frequently
      autoReloadTimer: null
    };
  },

  computed: {
    profileId: {
      get() {
        return this.$root.profileId;
      },
      set(newVal) {
        this.$root.profileId = newVal;
      }
    },
  },

  created() {
    if (this.profileId)
      this.load();
  },

  destroyed() {
    clearTimeout(this.autoReloadTimer);
  },

  methods: {
    load() {
      // Doesn't handle paging, but unlikely to be an issue at the moment
      this.items = null;
      this.promise = this.silentLoad();
    },

    async silentLoad() {
      try {
        this.isSilentLoading = true;
        clearTimeout(this.silentLoadingTimer);
        clearTimeout(this.autoReloadTimer);

        if (this.cancelToken)
          this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let response = await this.$api.get(`/profiles/${this.profileId}/monitored-resources`, {
          params: {
            page_size: 100
          },
          cancelToken: this.cancelToken.token
        });
        this.items = response.data.items;
      } finally {
        this.silentLoadingTimer = setTimeout(() => {
          this.isSilentLoading = false;
        }, 1000);
        this.autoReloadTimer = setTimeout(this.silentLoad, 30*1000);
      }
    }
  },

  watch: {
    profileId() {
      this.load();
    }
  }
};
</script>
