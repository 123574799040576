<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span>Download</span>
        <v-btn text icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Download or share a zip archive of selected captures. Links are valid
          for 7 days.
        </p>

        <v-expansion-panels v-model="panel" class="panels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <span class="font-weight-medium">Profile and dates</span>
                <v-fade-transition>
                  <span v-if="!open" class="mx-3 flex-grow-0 text--secondary">
                    {{ profileId || "Select a profile" }} /
                    {{ computedPeriod ? computedPeriod.formatted : "Select a period" }}
                  </span>
                </v-fade-transition>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <profile-picker
                v-model="profileId"
                @input="reloadFilters"
                :disabled="isLoading"
              />
              <period-picker
                v-model="period"
                @computed="computedPeriod = $event"
                :disabled="isLoading"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <span class="font-weight-medium">Filters</span>
                <v-fade-transition>
                  <span v-if="!open && enabledFilters.length" class="mx-3 flex-grow-0 text--secondary">
                    <template v-if="enabledFilters.length === 1">
                      {{enabledFilters[0].id}} is {{enabledFilters[0].value}}
                    </template>
                    <template v-else>
                      {{enabledFilters.length}} active filters
                    </template>
                  </span>
                </v-fade-transition>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <filters-list v-model="filters" :disabled="isLoading" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-card-text>
      <v-card-actions>
        <template v-if="isLoading">
          <v-spacer />
          <p class="grey--text mb-1">
            <v-progress-circular
              indeterminate
              width="2"
              size="16"
              class="mr-2"
              color="grey"
            />
            Creating archive. This may take several minutes.
          </p>
        </template>
        <template v-else-if="error">
          <v-spacer />
          <p class="error--text mb-1">
            An error occurred<template v-if="error.response">: {{error.response.data.detail}}</template>
          </p>
        </template>
        <template v-else-if="archive">
          <v-text-field
            ref="link"
            readonly
            outlined
            dense
            hide-details
            label="Archive link"
            :value="link"
            class="mr-2"
            @focus="focusLink"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="copyLink">
                    mdi-content-copy
                  </v-icon>
                </template>
                <span v-if="!linkCopied">Copy link</span>
                <span v-else>Copied!</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <v-btn text color="primary" :href="link" target="_blank">
            <v-icon left>mdi-download</v-icon>
            Download
          </v-btn>
        </template>
        <template v-else>
          <v-spacer />
          <v-btn
            text
            color="primary"
            :disabled="!profileId || !computedPeriod"
            @click="create"
            >Create Archive</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfilePicker from './ProfilePicker.vue';
import PeriodPicker from './PeriodPicker.vue';
import FiltersList from './FiltersList.vue';
import Filters from "../filters";
import dayjs from 'dayjs';
import axios from 'axios';

export default {
  name: "ArchiveDialog",
  components: {ProfilePicker, PeriodPicker, FiltersList},

  data() {
    return {
      showDialog: false,
      panel: 0,

      profileId: null,
      period: null,
      computedPeriod: null,

      filters: null,

      isLoading: false,
      error: null,
      cancelToken: null,
      waitTimer: null,

      archive: null,
      linkCopied: false,
    }
  },

  computed: {
    enabledFilters() {
      return this.filters.enabled;
    },

    link() {
      if (!this.archive)
        return null;
      return this.$apiBaseURL + '/archives/' + this.archive.id + ':download';
    }
  },

  methods: {
    open(profileId, period, filters) {
      this.profileId = profileId;
      this.period = period;

      if (!filters)
        this.reloadFilters()
      else 
        this.filters = filters;

      this.archive = null;
      this.isLoading = false;
      this.error = null;
      this.showDialog = true;
    },

    close() {
      this.showDialog = false;
      if (this.cancelToken !== null) {
        this.cancelToken.cancel();
      }
      clearTimeout(this.waitTimer);
    },

    async create() {
      this.isLoading = true;
      this.cancelToken = axios.CancelToken.source();

      let startTime = dayjs(this.computedPeriod.start);
      let endTime = dayjs(this.computedPeriod.end);
      let startTimeISO = startTime.startOf('day').toISOString();
      let endTimeISO = endTime.add(1, 'day').startOf('day').toISOString();

      let archiveParams = {
        profile_id: this.profileId,
        expire_in: 'P7D'
      };

      try {
        if (this.enabledFilters.length) {
          archiveParams.deliverable_ids = await this.getDeliverableIds(startTimeISO, endTimeISO);
        } else {
          archiveParams.start_time = startTimeISO;
          archiveParams.end_time = endTimeISO;
        }

        let response = await this.$api.post('/archives', archiveParams, {
          cancelToken: this.cancelToken.token
        });

        this.archive = response.data;
        this.waitUntilComplete();

      } catch(err) {
        if (axios.isCancel(err))
          return;

        this.error = err;
        this.isLoading = false;
      }
    },

    async getDeliverableIds(startTimeISO, endTimeISO) {
      let nextPageToken = null;
      let items = [];

      do {
        let response = await this.$api.get('/deliverables', {
          params: {
            profile_id: this.profileId,
            start_time: startTimeISO,
            end_time: endTimeISO,
            page_token: nextPageToken,
            page_size: 100
          },
          cancelToken: this.cancelToken.token
        });

        items = items.concat(this.filters.apply(response.data.items));
        nextPageToken = response.data.next_page_token;

      } while (nextPageToken);

      return items.map((deliverable) => deliverable.id);
    },

    waitUntilComplete() {
      let delay = 2000;

      let callback = async () => {
        try {
          let response = await this.$api.get('/archives/' + this.archive.id, {
            cancelToken: this.cancelToken.token
          });

          this.archive = response.data;
          if (this.archive.status === 'complete') {
            this.isLoading = false;
            return;
          } else if (this.archive.status === 'failed') {
            this.error = {};
            this.isLoading = false;
            return;
          }
        } catch (err) {
          if (axios.isCancel(err))
            return;

          if (err.response) {
            this.error = err;
            this.isLoading = false;
            return;
          }
        }

        delay = Math.min(delay * 2, 16000);
        this.waitTimer = setTimeout(callback, delay);
      }

      this.waitTimer = setTimeout(callback, delay);
    },

    focusLink(event) {
      setTimeout(() => {
        event.target.select();
        event.target.setSelectionRange(0, 99999)
      }, 50);
    },

    copyLink() {
      let input = this.$refs.link.$el.querySelector('input');
      input.select();
      input.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(this.link);
      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 1000);
    },

    async reloadFilters() {
      this.filters = new Filters();
      let profile = await this.$root.getProfile(this.profileId);
      this.filters = Filters.fromSchema(profile.schemas.deliverable);

      if (this.$root.isJDHack(this.profileId)) {
        for (let filter of this.filters) {
          if (filter.id == "opted_in") {
            filter.value = true;
            filter.enabled = true;
            filter.readonly = true;
          }
        }
      }
    }
  },

  watch: {
    profileId() {
      this.error = null;
      this.archive = null;
    },

    period() {
      this.error = null;
      this.archive = null;
    },

    filters() {
      this.error = null;
      this.archive = null;
    }
  }
};
</script>