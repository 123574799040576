<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <div>
        <h2 class="text-h4 mb-1">Email Templates</h2>
        <span class="subtitle-1 font-weight-light">Create and manage the email messages that are sent to guests.</span>
      </div>
      <v-btn v-if="canListAssets" text @click="openAssetLibrary">
        <v-icon left>mdi-image-multiple</v-icon>
        Asset Library
      </v-btn>
    </div>

    <!-- Card -->
    <v-card>
      <v-card-header class="d-flex justify-space-between align-center">
        <profile-picker v-model="profileId" />
        <v-btn v-if="canCreate" text :to="{name: 'template-create', params: {profileId}}">
          <v-icon left>mdi-plus</v-icon>
          Create
        </v-btn>
      </v-card-header>
      <v-card-text>
        <!-- Table -->
        <v-simple-table v-if="items.length">
          <thead>
            <tr>
              <th>ID</th>
              <th>Subject</th>
              <th>Last updated time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>
                <router-link :to="{name: 'template-detail', params: {profileId: item.profile_id, id: item.id}}">
                  {{item.id}}
                </router-link>
              </td>
              <td>{{item.subject}}</td>
              <td>{{$formatDateTime(item.update_time)}}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <async-state v-if="profileId" :promise="promise" :has-data="items.length > 0">
          <div v-if="nextPageToken" class="text-center my-4">
            <v-btn text @click="loadMore">
              Load more
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </async-state>
        <p v-else-if="!profileId" class="text-center grey--text my-4">
          Select a profile to view templates
        </p>
      </v-card-text>
    </v-card>

    <delivery-assets-dialog ref="assetLibrary" />
  </v-container>
</template>

<script>
import axios from 'axios'
import ProfilePicker from '../components/ProfilePicker.vue'
import AsyncState from '../components/AsyncState.vue'
import DeliveryAssetsDialog from '../components/DeliveryAssetsDialog.vue'

export default {
  name: "Templates",
  components: {ProfilePicker, AsyncState, DeliveryAssetsDialog},

  data() {
    return {
      items: [],
      promise: null,
      nextPageToken: null,
      cancelToken: null
    };
  },

  computed: {
    profileId: {
      get() {
        return this.$root.profileId;
      },
      set(newVal) {
        this.$root.profileId = newVal;
      }
    },

    canCreate() {
      return (
        this.profileId &&
        this.$root.permissions.canPerform("create_delivery_template", {profile: this.profileId}));
    },

    canListAssets() {
      return (
        this.profileId &&
        this.$root.permissions.canPerform("list_delivery_assets", {profile: this.profileId}));
    },
  },

  created() {
    if (this.profileId)
      this.loadInit();
  },

  methods: {
    loadInit() {
      this.items = [];
      this.nextPageToken = null;
      this.loadMore();
    },

    loadMore() {
      this.promise = (async () => {
        if (this.cancelToken)
          this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let response = await this.$api.get(`/profiles/${this.profileId}/delivery-templates`, {
          params: {
            page_size: 20,
            page_token: this.nextPageToken
          },
          cancelToken: this.cancelToken.token
        });
          
        this.items = this.items.concat(response.data.items);
        this.nextPageToken = response.data.next_page_token;
      })();
    },

    openAssetLibrary() {
      this.$refs.assetLibrary.open(this.profileId);
    }
  },

  watch: {
    profileId() {
      this.loadInit();
    }
  }
};
</script>
