<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <div>
        <h2 class="text-h4 mb-1">Stats</h2>
        <span class="subtitle-1 font-weight-light">Analyse key metrics over time with insights into usage, engagement and system performance.</span>
      </div>
    </div>
    
    <!-- Chart -->
    <v-card>
      <v-card-header class="d-flex justify-space-between align-center flex-wrap">
        <profile-picker v-model="profileId" />

        <v-btn-toggle v-if="hasNonZeroStats" v-model="selectedStatsGroup" mandatory dense color="teal">
          <v-btn
            v-for="(group, index) in statsGroups"
            :key="index"
            :value="group.value"
            class="px-4 text-none"
          >
            {{ group.label }}
          </v-btn>
        </v-btn-toggle>
        
        <div>
          <period-picker v-model="period" @computed="computedPeriod = $event" />
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text 
                v-on="on"
                v-bind="attrs"
                style="text-transform: capitalize;"
                class="text-transform-none text-subtitle-1"
              >
                By {{ binSize }}
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item @click="binSize = 'day'">
                  <v-list-item-title>By Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="binSize = 'month'">
                  <v-list-item-title>By Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-card-header>

      <v-card-text>
        <async-state v-if="profileId && computedPeriod" :promise="promise">
          <v-chart v-if="hasNonZeroStats" ref="chart" class="chart" :option="chartOption" autoresize />
          <p v-else class="grey--text text-center my-4">
            Nothing to display. Try selecting a wider date range.
          </p>
        </async-state>
        <p v-else-if="!profileId" class="grey--text text-center my-4">
          Select a profile to view stats
        </p>
      </v-card-text>
      <div v-if="hasNonZeroStats" class="d-flex align-center justify-end pa-4 pt-0">
        <v-btn small
          text @click="captureScreenshot()">
          Download Image
          <v-icon right>mdi-download</v-icon>
        </v-btn>
      </div>
    </v-card>

    <!-- Figures -->
    <v-card v-if="profileId && computedPeriod && hasNonZeroStats" class="mt-6">
      <v-card-header class="d-flex align-center">
        <v-card-title>
          Figures&nbsp;
          <span v-if="statsGroups.length" class="teal--text" style="opacity: .7;">({{selectedStatsGroupLabel}})</span>
        </v-card-title>
        <v-spacer/>
        <v-btn text @click="downloadFiguresCSV()">
          Download CSV
          <v-icon right>mdi-download</v-icon>
        </v-btn>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise">
          <stats-table
            :items="items"
            :totals="totals"
            :selected-stats-group="selectedStatsGroup"
            :stats-config="statsConfig"
            :revenue-currency="revenueCurrency"
            :colors="colors"
            :bin-size="binSize"
          />
        </async-state>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import ProfilePicker from "../components/ProfilePicker.vue"
import PeriodPicker from "../components/PeriodPicker.vue"
import AsyncState from "../components/AsyncState.vue"
import StatsTable from "../components/StatsTable.vue" 
import dayjs from "dayjs"
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {GridComponent, TooltipComponent, LegendComponent} from "echarts/components"
import VChart from "vue-echarts"
import axios from 'axios';

use([CanvasRenderer, LineChart, GridComponent, TooltipComponent, LegendComponent]);

export default {
  name: "Stats",
  components: { ProfilePicker, PeriodPicker, VChart, AsyncState, StatsTable },

  data() {
    return {
      period: "last30Days",
      computedPeriod: null,
      binSize: "day",
      
      items: null,

      promise: null,
      cancelToken: null,
      selectedStatsGroup: 'overview',
      statsGroups: [],
      revenueCurrency: null,
      colors: {
        red: '#ee6666',
        lightBlue: '#4FC3F7',
        blue: '#5470c6',
        yellow: '#fac858',
        lightGreen: '#91cc75',
        darkGreen: '#559F57',
        orange: '#F57F17', 
        pink: '#F495B5'
      },
      statsConfig: null
    };
  },

  computed: {
    profileId: {
      get() {
        return this.$root.profileId;
      },
      set(newVal) {
        this.$root.profileId = newVal;
      }
    },

    totals() {
      if (!this.items)
        return {};

      function addObject(src, dst) {
        for (let key in src) {
          if (typeof src[key] === "object") {
            dst[key] = dst[key] || {};
            addObject(src[key], dst[key]);
          } else {
            dst[key] = (dst[key] || 0) + src[key];
          }
        }
      }

      let totals = {};

      for (let item of this.items) {
        addObject(item.stats[0].metrics, totals);
      }
      return totals;
    },

    hasNonZeroStats() {
      return this.items && (
        this.totals.submissions.total > 0 ||
        this.totals.deliverables.total > 0 ||
        this.totals.deliveries.total > 0 ||
        this.totals.payments.complete > 0
      );
    },

    visibleStats() {
      const config = this.statsConfig[this.selectedStatsGroup];
      if (!config) return [];

      return config.filter(stat => {
        if (this.selectedStatsGroup === "overview") {
          // For overview, only show stats with non-zero totals
          const value = this.getStatValue(this.totals, stat.path);
          return value > 0;
        }
        return true; 
      });
    },

    revenueNumberFormatter() {
      try {
        if (this.revenueCurrency)
          return new Intl.NumberFormat(undefined, {style: "currency", currency: this.revenueCurrency}).format;
      } catch(err) {
        console.warn("Failed to create currency number formatter", err);
      }
      return null;
    },

    chartOption() {
      if (!this.items) return null;
      
      let series = this.configureStatsOptions();
      let yAxis = [{
        type: 'value',
      }]

      if ((this.selectedStatsGroup === 'overview' || this.selectedStatsGroup === 'payments') && this.revenueCurrency) {
        yAxis.push({
          type: 'value',
          name: `Revenue (${this.revenueCurrency})`,
          position: 'right',
          axisLabel: {
            formatter: this.revenueNumberFormatter,
          },
        });
      }

      return {
        legend: { show: true },
        tooltip: { trigger: "axis" },
        xAxis: {
          type: 'category',
          data: this.items.map(item => this.formatDate(item.time)),
          boundaryGap: false
        },
        yAxis: yAxis,
        series: series
      };
    },

    selectedStatsGroupLabel() {
      const group = this.statsGroups.find(group => group.value === this.selectedStatsGroup);
      return group ? group.label : this.selectedStatsGroup;
    },
  },

  methods: {
    load() {
      this.items = null;

      this.promise = (async () => {
        if (this.cancelToken) this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let start_time = dayjs.utc(this.computedPeriod.start).startOf('day').toISOString();
        let end_time = dayjs.utc(this.computedPeriod.end).add(1, 'day').startOf('day').toISOString();

        let response = await this.$api.get("/stats/time-series", {
          params: {
            profile_id: this.profileId,
            bin_size: this.binSize,
            start_time,
            end_time
          },
          cancelToken: this.cancelToken.token
        });
        this.items = response.data.items;
        this.revenueCurrency = this.lookForCurrencyInItems();
        this.statsConfig = {
          overview: [
            { label: 'Captures', path: 'deliverables.total', color: 'red' },
            { label: 'Emails Sent', path: 'deliveries.sent', color: 'blue' },
            { label: 'Opted In', path: 'submissions.opted_in', color: 'yellow' },
            { label: `Revenue (${this.revenueCurrency})`, 
              path: 'payments.revenue' + `.${this.revenueCurrency}`, 
              color: 'darkGreen', isCurrency: true 
            }
          ],
          payments: [
            { label: 'Completed', path: 'payments.complete', color: 'lightGreen' },
            { label: 'Cancelled', path: 'payments.cancelled', color: 'yellow' },
            { label: 'Declined', path: 'payments.declined', color: 'orange' },
            { label: 'Failed', path: 'payments.failed', color: 'red' },
            { label: `Revenue (${this.revenueCurrency})`, 
              path: 'payments.revenue' + `.${this.revenueCurrency}`, 
              color: 'darkGreen', isCurrency: true 
            }
          ],
          deliveries: [
            { label: 'Sent', path: 'deliveries.sent', color: 'lightBlue' },
            { label: 'Delivered', path: 'deliveries.delivered', color: 'lightGreen' },
            { label: 'Bounced', path: 'deliveries.bounced', color: 'yellow' },
            { label: 'Suppressed', path: 'deliveries.suppressed', color: 'orange' },
            { label: 'Complained', path: 'deliveries.complained', color: 'red' },
          ]
        }
        this.configureStatsToggle()
      })();
    },

    formatDate(date) {
      return this.binSize === "day"
        ? dayjs(date).format("L")
        : dayjs(date).format("MMMM YYYY");
    },

    configureStatsOptions() {
      let series = [];
      const config = this.statsConfig[this.selectedStatsGroup];
      
      if (config) {
        config.forEach(stat => {
          if (stat.isCurrency && this.revenueCurrency) {
            series.push({
              name: `Revenue (${this.revenueCurrency})`,
              data: this.items.map(item => {
                return this.getStatValue(item.stats[0].metrics, stat.path, stat.isCurrency)
              }),
              type: 'line',
              color: this.colors.darkGreen,
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: this.revenueNumberFormatter
              }
            });
          } else { 
            if (this.selectedStatsGroup === 'overview' && !this.getStatValue(this.totals, stat.path, stat.isCurrency)) return;
            
            series.push({
              name: stat.label,
              data: this.items.map(item => this.getStatValue(item.stats[0].metrics, stat.path)),
              type: 'line',
              color: this.colors[stat.color],
            });
          }

        });
      }

      return series;
    },

    getStatValue(obj, path, isCurrency = false) {
      let arr = path.split(".")
      let value = arr.reduce((acc, part) => acc && acc[part], obj) || 0;
      
      if (isCurrency) {
        return value / 100;
      }
      return value;
    },

    lookForCurrencyInItems() { // looks for first valid currency
      let foundCurrency = null;
      for (const item of this.items) {
        const revenueMetrics = item.stats[0].metrics.payments.revenue;
        const validKeys = Object.keys(revenueMetrics).filter(key => revenueMetrics[key] > 0);

        if (validKeys.length > 0) {
          foundCurrency = validKeys[0];
          break;
        }
      }
      return foundCurrency || null;
    },

    configureStatsToggle() {
      this.statsGroups = [];
      if (this.totals.deliveries?.total > 0) this.statsGroups.push({ value: 'deliveries', label: 'Emails Sent' });
      if (this.totals.payments?.total > 0) this.statsGroups.push({ value: 'payments', label: 'Payments' });
      if (this.statsGroups.length > 0) this.statsGroups.unshift({ value: 'overview', label: 'Overview' });
    },

    downloadFiguresCSV() {
      let rows = this.generateCSVRows();
      let name = this.generateFileName() + ' Stats'
      let csvData = this.convertToCSV(rows);
      
      this.downloadData(csvData, 'text/csv', name + '.csv');
    },

    generateCSVRows() {
      const config = this.visibleStats;
      let headers = ['Date', ...config.map(stat => stat.label)];
      let rows = [headers];

      for (let item of this.items) {
        let date = this.formatCSVDate(item.time);
        let metrics = item.stats[0].metrics;

        let row = [date];
        config.forEach(stat => {
          let value = this.getStatValue(metrics, stat.path, stat.isCurrency);
          row.push(value || 0);
        });

        rows.push(row);
      }

      // Add total row
      let totalRow = ['Total'];
      config.forEach(stat => {
        let value = this.getStatValue(this.totals, stat.path, stat.isCurrency);
        totalRow.push(value || 0);
      });
      rows.push(totalRow);

      return rows;
    },

    formatCSVDate(date) {
      return this.binSize === "day"
        ? dayjs(date).format('YYYY-MM-DD')
        : dayjs(date).format("MMMM YYYY");
    },

    generateFileName() {
      let startTime = dayjs(this.computedPeriod.start);
      let endTime = dayjs(this.computedPeriod.end);
      return `${this.profileId} - ${startTime.format('L')} - ${endTime.format('L')} - ${this.selectedStatsGroupLabel}`;
    },

    convertToCSV(rows) {
      return rows.map(row => row.map(col => `"${col}"`).join(',')).join('\n');
    },

    downloadData(data, type, name) {
      let file = new Blob([data], { type });
      let a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = name;
      a.click();
    },

    captureScreenshot() {
      if (this.$refs.chart) {
        // Access the ECharts instance
        const chartInstance = this.$refs.chart.chart;
        if (chartInstance) {
          // Generate the image URL
          const imageUrl = chartInstance.getDataURL({
            type: 'png',
            pixelRatio: 4, // Increase for higher quality
            backgroundColor: '#fff' // Set background color
          });

          // Download the image
          this.downloadImage(imageUrl, this.generateFileName() + ' Chart.png');
        }
      } 
    },

    downloadImage(url, filename) {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },

  watch: {
    async profileId() {
      this.selectedStatsGroup = 'overview',
      this.statsGroups = []
      this.revenueCurrency = null
      this.statsConfig = null
      this.load();
    },

    computedPeriod() {
      if (this.profileId) this.load();
    },

    binSize() {
      if (this.profileId) this.load();
    }
  }
}
</script>

<style scoped>
.chart {
  padding-top: .5rem;
  height: 400px;
}
</style>