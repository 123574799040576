<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    offset-y
    nudge-bottom="4"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        class="text-transform-none text-subtitle-1"
        v-on="on"
        v-bind="attrs"
        :disabled="disabled"
      >
        <template v-if="computedValue">{{computedValue.formatted}}</template>
        <template v-else>Select a period</template>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-text class="pa-2">
        <div class="d-flex">
          <v-list class="mr-2">
            <v-list-item-group color="primary" :value="computedValue && computedValue.preset" @change="select" :mandatory="isPreset">
              <v-list-item v-for="preset in presets" :key="preset" :value="preset">
                <v-list-item-title>{{presetTitles[preset]}}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-divider vertical />
          <date-picker class="date-picker" :value="computedValue" @input="select" is-range />
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { DatePicker } from 'v-calendar';

import dayjs from 'dayjs';

export default {
  name: "PeriodPicker",
  components: {DatePicker},

  props: {
    value: [String, Object],
    disabled: Boolean,
    showRecent: Boolean
  },

  data: () => ({
    showMenu: false,
    presetTitles: {
      "recent": "Most Recent",
      "today": "Today",
      "yesterday": "Yesterday",
      "last7Days": "Last 7 Days",
      "last30Days": "Last 30 Days",
      "last90Days": "Last 90 Days",
      "last180Days": "Last 6 Months",
    }
  }),

  computed: {
    presets() {
      let presets = ["today", "yesterday", "last7Days", "last30Days", "last90Days", "last180Days"];
      if (this.showRecent)
        presets.unshift("recent");
      return presets;
    },

    isPreset() {
      return (typeof this.value === 'string');
    },

    computedValue() {
      if (this.isPreset) {
        let result = {
          preset: this.value,
          formatted: this.presetTitles[this.value]
        }
        
        if (this.value === 'today') {
          let today = dayjs().toDate();
          result.start = today;
          result.end = today;
        } else if (this.value === 'yesterday') {
          let yesterday = dayjs().subtract(1, 'day').toDate();
          result.start = yesterday;
          result.end = yesterday;
        } else if (this.value === 'last7Days') {
          let today = dayjs();
          result.start = today.subtract(6, 'days').toDate();
          result.end = today.toDate();
        } else if (this.value === 'last30Days') {
          let today = dayjs();
          result.start = today.subtract(29, 'days').toDate();
          result.end = today.toDate();
        } else if (this.value === 'last90Days') {
          let today = dayjs();
          result.start = today.subtract(89, 'days').toDate();
          result.end = today.toDate();
        } else if (this.value === 'last180Days') {
          let today = dayjs();
          result.start = today.subtract(179, 'days').toDate();
          result.end = today.toDate();
        } 
        
        return result;

      } else if (this.value) {
        let start = dayjs(this.value.start);
        let end = dayjs(this.value.end);

        return {
          preset: null,
          start: start.toDate(),
          end: end.toDate(),
          formatted: start.isSame(end, 'day') ? start.format('L') : start.format('L') + ' - ' + end.format('L')
        }
      } else
        return null;
    },
  },

  methods: {
    select(value) {
      this.$emit("input", value);
      this.showMenu = false;
    }
  },

  watch: {
    computedValue: {
      handler(newVal) {
        this.$emit("computed", newVal);
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.text-transform-none {
  text-transform: none;
}

.date-picker {
  border: none;
}

</style>