<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <div>
        <h2 class="text-h4 mb-1">Payments</h2>
        <span class="subtitle-1 font-weight-light">Track transactions and monitor revenue from paid experiences.</span>
      </div>
    </div>

    <!-- Card -->
    <v-card>
      <v-card-header class="d-flex justify-space-between align-center">
        <profile-picker v-model="profileId" />
        <div>
          <period-picker v-model="period" @computed="computedPeriod = $event" show-recent />
        </div>
      </v-card-header>
      <v-card-text>
        <!-- Table -->
        <PaymentsTable
          v-if="profileId && computedPeriod"
          :profile-id="profileId"
          :start-time="startTime"
          :end-time="endTime"
          :reverse="computedPeriod.preset === 'recent'"
          :page-size="20"/>
        <p v-else-if="!profileId" class="grey--text text-center my-4">
          Select a profile to view Payments
        </p>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import ProfilePicker from "../components/ProfilePicker";
import PeriodPicker from "../components/PeriodPicker.vue";
import PaymentsTable from "../components/PaymentsTable.vue";
import dayjs from 'dayjs';

export default {
  name: "Payments",
  components: { ProfilePicker, PeriodPicker, PaymentsTable },

  data() {
    return {
      period: "recent",
      computedPeriod: null,
    };
  },

  computed: {
    profileId: {
      get() {
        return this.$root.profileId;
      },
      set(newVal) {
        this.$root.profileId = newVal;
      }
    },

    startTime() {
      return this.computedPeriod.start ? dayjs(this.computedPeriod.start).startOf("day").toDate() : null;
    },

    endTime() {
      return this.computedPeriod.end ? dayjs(this.computedPeriod.end).add(1, "day").startOf("day").toDate() : null;
    }
  },

};
</script>

