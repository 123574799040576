<template>
  <v-container class="px-lg-12">
    <v-form ref="form" v-model="validated" v-if="profile">
      <!-- Header -->
      <div class="d-flex justify-space-between align-center mb-6">
        <h2 class="text-h5">
          <router-link :to="{name: 'profile-admin'}">Profiles</router-link>
          <v-icon class="mx-2">mdi-chevron-right</v-icon>
          <template v-if="profileId">
            <router-link :to="{name: 'profile-detail', params: {profileId: profileId}}">
              {{profileId}}
            </router-link>
            <v-icon class="mx-2">mdi-chevron-right</v-icon>
            <span>Edit</span>
          </template>
          <span v-else>Create</span>
        </h2>
        <div>
          <v-btn text exact :to="{name: 'profile-detail', params: {profileId: profileId}}">
            <v-icon left>mdi-close</v-icon>
            Cancel
          </v-btn>
          <v-btn text color="primary" :loading="isSaving" :disabled="!profile || !validated" @click="save">
            <v-icon left>mdi-check</v-icon>
            Save
          </v-btn>
        </div>
      </div>

      <!-- Details -->
      <v-card>
        <v-card-header>
          <v-card-title class="pa-0">Details</v-card-title>
        </v-card-header>
        <v-card-text>
          <async-state :promise="promise">
            <template v-slot:default>
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                    :disabled="Boolean(profileId)"
                    label="ID"
                    v-model="profile.id"
                    :rules="[requiredRule, extendedWordRule]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    disabled
                    label="Created Time"
                    v-model="createdTime"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    label="Name"
                    v-model="profile.name"
                    maxLength="50"
                  />
                </v-col>
              </v-row>
            </template>
          </async-state>
        </v-card-text>
      </v-card>

      <!-- Schemas -->
      <v-card class="mt-6">
        <v-card-header class="d-flex justify-space-between align-center">
          <v-card-title class="pa-0">
            <span>Schemas</span>
          </v-card-title>
          <v-btn-toggle v-model="schemaType" group dense mandatory>
            <v-btn value="submission" class="text-none">Submission</v-btn>
            <v-btn value="deliverable" class="text-none">Deliverable</v-btn>
          </v-btn-toggle>
          <v-btn text @click="addAttribute">
            <v-icon left>mdi-plus</v-icon>
            Add
          </v-btn>
        </v-card-header>
        <v-card-text>
          <async-state :promise="promise" :has-data="profile.schemas[`${schemaType}`].attributes.length > 0">
            <template #default>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Attribute</th>
                    <th>Type</th>
                    <th>Personal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(attribute, index) in profile.schemas[`${schemaType}`].attributes" :key="index">
                    <td width="33%">
                      <v-text-field v-model="attribute.id" :rules="[requiredRule]"/>
                    </td>
                    <td width="33%">
                      <v-select class="text-capitalize"
                        :items="['string', 'number', 'boolean']"
                        label=""
                        v-model="attribute.type"
                      ></v-select>
                    </td>
                    <td>
                      <v-checkbox v-model="attribute.personal" label="Personal"></v-checkbox> 
                    </td>
                    <td width="1%">
                      <v-btn icon @click="removeAttribute(index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>
          </async-state>
        
        </v-card-text>
      </v-card>

      <!-- Retention Policy -->
      <v-card class="mt-6">
        <v-card-header class="d-flex justify-space-between align-center" style="max-height:68px;">
          <v-card-title class="pa-0">
            <span>Retention Policy</span>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-switch
            v-model="profile.retention_policy.enabled"
            label="Enabled" inset dense class="pr-5"
          ></v-switch>
        </v-card-header>
        <v-card-text :class="{ 'disabled' : !profile.retention_policy.enabled }">
          <async-state :promise="promise">
            <v-row class="row-divided" >
              <v-col sm="6" cols="12" class="pr-6">
                <v-text-field type="number" 
                  v-model.number="profile.retention_policy.duration_days" 
                  label="Duration"
                  suffix="Days" 
                  :rules="[unsignedIntegerRule]"
                  />
              </v-col>
              <v-col sm="6" cols="12" class="px-5">
                <v-checkbox style="margin: 0;"
                  v-model="profile.retention_policy.erase_submissions"
                  label="Erase Submissions"
                ></v-checkbox>
                <v-checkbox style="margin: 0;"
                  v-model="profile.retention_policy.erase_deliverables"
                  label="Erase Deliverables"
                ></v-checkbox>
                <v-checkbox style="margin: 0;"
                  v-model="profile.retention_policy.erase_deliveries"
                  label="Erase Deliveries"
                ></v-checkbox>
              </v-col>
            </v-row>
          </async-state>
        </v-card-text>
      </v-card>

      <!-- Alerts -->
      <v-card class="mt-6">
          <v-card-header class="d-flex justify-space-between align-center">
            <v-card-title class="pa-0">
              <span>Alerts</span>
            </v-card-title>
            <v-btn text @click="addAlert">
              <v-icon left>mdi-plus</v-icon>
              Add
            </v-btn>
          </v-card-header>
          <v-card-text>
            <async-state :promise="promise" :has-data="profile.alerts?.length > 0">
              <template #default>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Destination</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(alert,index) in profile.alerts" :key="index">
                      <td width="33%">
                        <v-select class="text-capitalize"
                          :items="['email']"
                          v-model="alert.type"
                        ></v-select>
                      </td>
                      <td>
                        <v-text-field v-model="alert.destination" :rules="[requiredRule,emailRule]"/>
                      </td>
                      <td width="1%">
                        <v-btn icon @click="removeAlert(alert)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </template>
            </async-state>
          </v-card-text>
        </v-card>

        <!-- External Links -->
        <v-card class="mt-6">
          <v-card-header class="d-flex justify-space-between align-center">
            <v-card-title class="pa-0">
              <span>External Links</span>
            </v-card-title>
            <v-btn text @click="addLink">
              <v-icon left>mdi-plus</v-icon>
              Add
            </v-btn>
          </v-card-header>
          <v-card-text>
            <async-state :promise="promise" :has-data="profile.external_links?.length > 0">
              <template #default>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(link,index) in profile.external_links" :key="index">
                      <td width="33%">
                        <v-select class="text-capitalize"
                          :items="['archbee','payter']"
                          v-model="link.type"
                        ></v-select>
                      </td>
                      <td>
                        <v-text-field v-model="link.url" :rules="[requiredRule, urlRule]" />
                      </td>
                      <td width="1%">
                        <v-btn icon @click="removeLink(index)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </template>
            </async-state>
          </v-card-text>
        </v-card>

    </v-form>
  </v-container>
</template>

<script>
import AsyncState from "../components/AsyncState"
import {requiredRule, extendedWordRule, unsignedIntegerRule, emailRule, urlRule} from "../validation"

export default {
  name: "ProfileForm",
  components: {AsyncState },

  props: {
    profileId: String
  },

  data() {
    return {
      profile: null,
      promise: null,
      validated: false,
      attributes: [],
      isSaving: false,
      schemaType: 'submission',
    };
  },

  async created() {
    this.promise = await this.load();
  },

  computed: {
    createdTime() {
      if(this.profileId) {
        return this.$formatDateTime(this.profile.create_time)
      } else {
        return "Auto-Generated"
      }
    },
    attributesLength() {
      return this.profile?.schemas[`${this.schemaType}`].attributes.length || 0;
    },

  },  

  methods: {
    async load() {
      if (this.profileId) {
        this.profile = null;
        let response = await this.$api.get("/profiles/" + this.profileId);
        this.profile = response.data;
      } else {
        this.profile = {
          id: '',
          name: '',
          schemas: {
            deliverable: {
              assume_personal: "strings",
              attributes: []
            },
            submission: {              
              assume_personal: "strings",
              attributes: []
            }
          },
          retention_policy: {
            duration_days: 183,
            enabled: true,
            erase_deliverables: true,
            erase_deliveries: true,
            erase_submissions: true
          }
        }
      }
    },

    requiredRule,
    extendedWordRule,
    unsignedIntegerRule,
    emailRule,
    urlRule,

    addAttribute() {
      this.profile.schemas[`${this.schemaType}`].attributes.push({
        id: `attribute${this.profile.schemas[`${this.schemaType}`].attributes.length+1}`,
        type: 'string',
        personal: false
      });
    },

    removeAttribute(index) {
      this.profile.schemas[`${this.schemaType}`].attributes.splice(index, 1);
    },
    addLink() {
      this.profile.external_links.push({
        type: 'archbee',
        url: ''
      });
    },
    removeLink(index) {
      this.profile.external_links.splice(index, 1);
    },

    addAlert() {
        this.profile.alerts.push({
          type: 'email',
          destination: ''
        });
    },

    removeAlert(alert) {
      this.profile.alerts.splice(this.profile.alerts.indexOf(alert), 1);
    },

    async save() {
      this.isSaving = true;
      
      this.profile.retention_policy.duration_days = this.profile.retention_policy.duration_days.toString()
      if (this.profileId) {
        this.updateProfile()
      } else {
        this.createProfile()
      }

    },

    async updateProfile() {     
      try {
        let response = await this.$api.put(`/profiles/${this.profileId}`, this.profile);
        this.$root.showSnackbar("Profile updated successfully");
        
        this.$router.push({name: "profile-detail", params: {id: response.data.id}});
      } catch(err) {
        this.$root.showSnackbar(err);
      } finally {
        this.isSaving = false;
      }
    },

    async createProfile() {     
      try {
        let response = await this.$api.post('/profiles', this.profile);
        this.$root.showSnackbar("Profile created successfully");
        
        this.$router.push({name: "profile-admin", params: {id: response.data.id}});
      } catch(err) {
        this.$root.showSnackbar(err);
      } finally {
        this.isSaving = false;
      }
    }
  },

};
</script>

<style scoped>
.iframe {
  width: 100%;
  height: 400px;
  border: none;
}

.editor >>> .CodeMirror {
  height: 300px;
}

.disabled {
  opacity: .4;
  pointer-events: none;
}
</style>